@use "styles/abstracts/functions" as *;

.modal::before {
  content: "";
  position: fixed;
  background-color: #f5f5f5e6;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: z("below");
  overflow: scroll;
}
.modal--hide {
  display: none;
}

.modal--open {
  display: block;
}

.modal__position-manager {
  position: fixed;
  top: 0;
  left: 0;
  // height: 80vh;
  width: 100%;
  z-index: z(modal);
}

.modal__body {
  position: fixed;
  top: 50%;
  left: 50%;
  max-width: rem(600);
  min-width: rem(400);
  width: 100%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  max-height: 80%;
  // padding: 1rem;
  overflow-y: auto;
  z-index: 5;
  background-color: #fff;
  @media (max-width: 414px) {
    min-height: 100%;
  }
}

.modal__body--shadow {
  box-shadow: 0px 10px 20px #0000000a;
}

.modal__content {
  padding-top: 0px;
  overflow-y: auto;
}

.modal__close {
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  // padding-top: 0px;
  border-bottom: 2px solid #2a2a2a0c;
  position: sticky;
  z-index: 20;
  background-color: #fff;
  left: 0;
  right: 0;
  top: 0;
  height: fit-content;
  @media (max-width: 414px) {
    max-height: 70px;
    min-height: 60px;
    position: relative;
    .modal__button {
      display: flex;
      flex-direction: column;
      align-items: center;
      // position: absolute;
      // top: -150px;
   
      & svg {
        font-size: 2rem;
        color: var(--clr-primary-300);
        transition: transform, color 0.2s ease-in;
      }

      &:hover svg {
        transform: scale(1.2);
      }

      &:active svg {
        color: #000;
      }
    }
  }
}
.modal__button {
  display: flex;
  flex-direction: column;
  align-items: center;

  & svg {
    font-size: 2rem;
    color: var(--clr-primary-300);
    transition: transform, color 0.2s ease-in;
  }

  &:hover svg {
    transform: scale(1.2);
  }

  &:active svg {
    color: #000;
  }
}

.col__item {
  margin-bottom: 2rem;
}
.col__flex {
  display: flex;
  align-items: center;

  & img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 100%;
    margin-right: 1rem;
  }
}

.contestant__personal {
  display: flex;
  flex-direction: column;
}

.contestant__category {
  font-family: var(--primary-font);
  font-size: 1.5rem;
}

.info__name,
.contestant__age {
  color: #2a2a2a7f;
}

.contestant__age {
  margin-left: 2rem;
}

.col__item--grey-bg {
  background-color: #2a2a2a05;
  padding: 1rem;

  & div {
    font-size: 1.15rem;
  }
}
