.jumbotron {
    min-height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.875rem;
    &> p {
        font-size: 0.875rem;
        color: #ccc
    }
}