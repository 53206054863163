/**
 * FLUID TYPE
 *
 * Scale text based on viewport and the following variables. Props should be unitless, but will be converted
 * to REM units. E.G '1' will be converted to '1rem'
 *
 * --fluid-type-min-size: The smallest font size. Default 1
 * --fluid-type-max-size: The largest font size: Default 2
 * --fluid-type-min-screen: The smallest viewport size that this works for: Default 20
 * --fluid-type-max-screen: The largest viewport size that this works for: Default 88
 */
.fluid-type {
  --fluid-type-min-size: 1;
  --fluid-type-max-size: 2;
  --fluid-type-min-screen: 20;
  --fluid-type-max-screen: 88;

  /* We multiply by 1rem to essentially stick a rem unit to a number. */
  font-size: calc(
    (var(--fluid-type-min-size) * 1rem) +
      (var(--fluid-type-max-size) - var(--fluid-type-min-size)) *
      (100vw - (var(--fluid-type-min-screen) * 1rem)) /
      (var(--fluid-type-max-screen) - var(--fluid-type-min-screen))
  );
}

/*
* SET LOCKS ON ELEMENTS
*/
h1.fluid-type {
  --fluid-type-min-size: 2;
  --fluid-type-max-size: 4;
  font-family: var(--primary-font);
}

h2.fluid-type {
  --fluid-type-min-size: 1.15;
  --fluid-type-max-size: 1.85;
  font-family: var(--primary-font);
}

p {
  font-size: 1.25rem;
  max-width: 75ch;
}
