.select-wraper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 0px 0px;
    text-align: left;
    padding: 5px;
    // background: var(--white);
    border-radius: 0.5rem;
  
    // border: 1px solid var(--grey-BBBBBB);
  }
  .select-container {
    position: relative;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .select-list {
    position: absolute;
    top: 50px;
    width: 100%;
    padding: 0;
    list-style-type: none;
    max-height: 400px;
    overflow-y: scroll;
    z-index: 2;
  }
  .select-container .input-text {
    background: #ffffff;
    border-color: #e9e9e9;
  }
  
  .select-option {
    padding: 11px;
    // font: var(--style-normal) normal 300 12px var(--open-sans);
    text-align: center;
    font-weight: normal;
    letter-spacing: 0px;
    color: var(--dark-303030);
    font-weight: 600;
    background: var(--white);
    opacity: 1;
    animation-duration: 4s;
    animation-delay: 2s;
  }
  .is-selected {
    background: #e9e9e9;
  }
  ::placeholder {
    // color: var(--dark-303030);
    font-size: 0.85rem;
  }
  .select-option:hover {
    background: #e9e9e9;
    // color: var(--dark-303030);
    cursor: pointer;
    opacity: 1;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  
  .select-container .input-text {
    padding: 15px;
    width: 100%;
    outline: none;
    border-radius: 3px;
    border: 1px solid #e6e6e6;
    min-height: 40px;
    font-size: 14px;
    font-weight: normal;
    // font-family: var(--open-sans);
  }
  .input-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 0px 0px;
    text-align: left;
    position: relative;
  }
  
  .select-wraper-label-disabled {
    color: grey;
  }
  .input-icon {
    display: flex;
    position: relative;
    width: inherit;
  }
  .select-icon-wrapper {
    position: absolute;
    right: 10px;
    top: 10px;
    // border: 3px solid var(--white);
    background: #f6f6f6;
    opacity: 1;
    cursor: pointer;
    height: 23px;
    width: 23px;
    border-radius: 50%;
    text-align: center;
  }
  .select-icon-wrapper svg {
    font-size: 1rem;
    position: relative;
    // color: var(--dark);
    bottom: 1px;
    opacity: 1;
  }
  
  .select-wraper-label {
    // color: var(--dark);
      font-weight: 400;
      padding-left: 5px;
      letter-spacing: 0;
      font-size: 0.9rem;
    //   font-family: var(--open-sans);
  }