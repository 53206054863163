

.resulttable__wrapper {
    width: 100%;
    padding-bottom: 4rem;
    padding-left: 4rem;
    padding-right: 4rem;
    margin-right: 0;
}

.result__table__container1 {
    padding: 1rem 0;
    width: 100%;
    border: none;

    tr {
        box-shadow: 0px 1px 1px #2a2a2a3f;
    }

    td {
        padding-right: 4rem;
        padding-top: .5rem;
        padding-bottom: .5rem;
        font-size: 16px;
        color: #2A2A2A7F;
    }


    .result__data__items {
        padding-left: 5px;
    }

    .result__data__itemss {
        padding-left: 5px;
    }

    th {
        padding-right: 4rem;
        padding-left: 5px;
        color: #2A2A2A;
        font-size: 18px;
        text-align: left;
        padding-bottom: 15px;
    }

    .del__edi__holder {
        display: flex;
        align-items: center;

        .edi {
            margin-right: 20px;
            cursor: pointer;
            color: #2A2A2A7F;
        }

        .dele {
            cursor: pointer;
            color: #E36176;
        }
    }

}

.view__modal__user {
    padding: 0rem 3rem;

    .edit__cate__holder {
        width: 100%;
    }
}

.edit__cate__title {
    padding-top: 1rem;
}


.add__new {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
    margin-right: 30px;

    .nwe {
        background-color: #D3A937;
        padding: .6rem 1rem;
        color: #fff;
        border-radius: 4px;
    }
}

.tabs__holder {
    display: flex;
    align-items: center;
    margin-left: 20px;

    .tab__btn {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: .6rem 1rem;
        margin-bottom: 30px;
    }
}

.btns2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4rem;
}

.c__btn {
    background: none;
    padding: .5rem 2rem;
    border-radius: 4px;
    border: 1px solid #D3A937;
}

.c__btn__1 {
    padding: .5rem 2rem;
    border-radius: 4px;
    color: white;
    background-color: #D3A937;
}

.sure__del {
    text-align: center;
    margin-bottom: 4rem;
    margin-top: 1rem;
}



.flex__modal {
    display: flex;
    justify-content: space-between;
}


// *************** @Media screens ***************
@media (max-width: 1740px) {

    .result__header__items > p {
        font-size: 14px;
    }

}
@media (max-width: 1024px) {

    .main-panel--content {
        padding-left: 10.75rem;
        padding-right: 0.25rem;
    }

        .resulttable__wrapper {
            padding-left: 4rem;
            padding-right: 0rem;
        }

}

@media (max-width: 820px) {

    .main-panel--content {
        padding-left: 0rem;
        padding-right: 0rem;
    }

}

@media (max-width: 768px) {

    .main-panel--content {
        padding-left: 0rem;
        padding-right: 0rem;
    }

}

@media (max-width: 540px) {

    .resulttable__wrapper {
        padding-left: 0rem;
        padding-right: 0rem;
    }

        .main-panel--content {
            padding-left: 0rem;
            padding-right: 0rem;
        }

    .result__table__container1 {
        padding: 0rem;
        border: none;

        tr {
            box-shadow: 0px 1px 1px #2a2a2a3f;
        }

        td {
            padding-right: 0rem;
            padding-top: .5rem;
        }
    }

    th {
        padding-right: 4rem;
        padding-left: 5px;
        color: #2A2A2A;
        font-size: 18px;
        text-align: left;
        padding-bottom: 15px;
    }
}


@media (max-width: 412px) {

    .view__modal__user {
        padding: 0rem 0rem;
    }

    .main-panel--content {
        padding-left: 0rem;
        padding-right: 0rem;
    }

}

@media (max-width: 280px) {

    // .avatar-button__toggle {
    //     margin-left: -14rem;
    // }

    .input-field--holder {
        width: 40%;

        & input[type="search"] {
            max-width: 8rem;
            margin-left: 1rem;
        }
    }

    .search-input--form {
        margin-left: 4rem;
        max-width: 50%;
    }

}