ul.pagination li {
    display: inline-block;
    width: 25px;
    height: 25px;
    border: 1px solid #e2e2e2;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  ul.pagination li a {
    display: block;
    text-decoration: none;
    color: var(--clr-primary-300);
    font-size: 1rem;
    font-weight: 500;
  }
  
  ul.pagination li.active a {
    color: #fff;
  }
  ul.pagination li a::selection {
    border: none;
  }
  ul.pagination li.active {
    background-color: var(--clr-primary-300);
  }
  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: var(--clr-primary-300);
  }
  ul.pagination li:hover {
    background: #ddd;
  }
  
  .page-selection {
    width: 48px;
    height: 30px;
    color: var(--dark);
  }
  
  .pagination {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
  }