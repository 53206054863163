a,
button {
  cursor: pointer;
  outline: 0;
  border: 1px solid transparent;
  font-family: inherit;
}

a:link {
  text-decoration: none;
  letter-spacing: 0.2px;
}
a:hover {
  color: var(--clr-primary-300);
}


