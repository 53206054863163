.page-inline-navigations > ul{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding-left: 1rem;
}



.page-inline-navigations > ul > li{
  display: flex;
  flex-direction: column;
  @media (max-width: 414px) {
    font-size: 1rem;
  }

}

.page-inline-navigations > ul > li:first-child > button{
  display: flex;
  align-items: center;
  font-size: 16px;
  background-color: transparent;
  border: 1px solid transparent;
  outline: none;
 

  &:hover, &:focus{
    text-decoration: underline;
    color: #D3A937;
  }

  & > span{
    margin-left: .5rem;
    margin-right: .5rem;
    color: #2A2A2A3F;
    font-weight: 600;
    font-size: 1.5rem;
    @media (max-width: 414px) {
      font-size: 1rem;
    }
  }
}

.page-inline-navigations > ul > li:last-child > span{
  margin-left: .5rem;
  font-weight: 600;
  font-size: 1.5rem;
  @media (max-width: 414px) {
    font-size: 1rem;
  }
}