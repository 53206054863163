@use "../abstracts/variables" as *;
@use "../base/font-face" as *;
@use "styles/abstracts/functions" as fn;

$main-panel-top-spacing: fn.rem(100);
$max-width: fn.rem(1639);

body {
  font-family: var(--lato-regular-font);
}

:root {
  @each $color, $shades in $colors {
    //shades is an object for more iteration
    @each $shade, $value in $shades {
      //create custom properties
      //this will take the form --clr-primary-100: #color-value
      --clr-#{$color}-#{$shade}: #{$value};
    }
  }

  --primary-font: "califb";
  --lato-regular-font: "Lato-Regular";
  --lato-light-font: "Lato-Light";
  --lato-bold-font: "Lato-Bold";
  --text-primary-color: #2a2a2a;
}

.lato-light-font {
  font-family: var(--lato-light-font);
}
.lato-bold-font {
  font-family: var(--lato-bold-font);
}
.lato-regular-font {
  font-family: var(--lato-regular-font);
}

.text-color-danger {
  color: #e36176;
}

.checkout {
  border: 1px solid red;
}

/**
 * PRIMARY-COLOR TEXT
 *
 * Add this class to an element to make it have the agreed 
 
  * primary color of these project
 */
.text-color-brand {
  color: var(--clr-primary-300);
}
/**
 * FULL BLEED
 *
 * Add this class to an element to make it fill the width of the screen 
 * and sit horizontally central
 */
.full-bleed {
  width: 100vw;
  margin-left: calc(50% - 50vw);
}

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  max-width: $max-width; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 20px; /* 3 */
  padding-right: 20px; /* 3 */
  width: 100%; /* 1 */
}
.container--narrow {
  max-width: calc($max-width - fn.rem(200)); /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 20px; /* 3 */
  padding-right: 20px; /* 3 */
  width: 100%; /* 1 */
}

//capitalize text
.navigation--link {
  color: var(--clr-dark-300);
  text-transform: uppercase;
  font-size: 0.85rem;
  font-family: var(--lato-regular-font);

  //Javascript purpose
  &.active-link {
    color: var(--clr-primary-300);
    font-weight: 600;
  }
}

/**
 * Main content block
 * 1. adds calculated top due to the header position of fixed
 */
.main-panel {
  padding-top: $main-panel-top-spacing; /*1*/
}
// .main-panel--content {
//   padding: fn.rem(50) 0 fn.rem(50) fn.rem(200);
// }

.button {
  outline: 0;
  border: 1px solid transparent;
  background-color: transparent;
}

input {
  background-color: #2A2A2A0C;
  outline: none;
  border: none;
}
// button with outer-pink border and txt (the cancled btn***********
.cancleBtn {
  background-color: unset;
  border: 1px solid #e36176;
  color: #e36176;
  padding: 0.65rem 1.15rem;
  border-radius: 4px;
  text-transform: uppercase;
}

// button large styling ***********
.larg-btn {
  background-color: #d3a937;
  color: #fff;
  padding: 0.65rem 5.15rem;
  border-radius: 4px;
  text-transform: uppercase;
}

.cancle-btn {
  background-color: unset;
  border: 1px solid var(--clr-primary-300);
  color: var(--clr-primary-300);
  padding: 0.65rem 1.15rem;
  border-radius: 4px;
  text-transform: uppercase;
}

 // *************** @Media screens ***************
 @media (max-width: 1740px) {
  th,
  td {
     font-size: 14px;

  }
  
}
