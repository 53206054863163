.area-container {
    .label {
        padding-bottom: .5rem;
        font-size: 16px;
        color: #2A2A2A;
    }
    .textarea {
        background-color: #2A2A2A0C;
        border: none;
        padding: .5rem;
        border-radius: .5rem;
        outline: none;
        width: 100%;

        &::placeholder {
            color: #2A2A2A7F;
            font-size: 16px;

        }
    }
}