

.resulttable__wrapper {
    width: 100%;
}

.result__table__container3 {
    padding: 1rem 0;
    width: 100%;
    border: none;
    margin-top: 30px;

    tr {
        box-shadow: 0px 1px 1px #2a2a2a3f;
        padding: 0px;
    }

    td {
        padding-right: 4rem;
        padding-top: .5rem;
        padding-bottom: .5rem;
        font-size: 16px;
        color: #2A2A2A7F;
    }


    .result__data__items {
        padding-left: 5px;
    }

    .result__data__itemss {
        padding-left: 0px;
    }

    th {
        padding-right: 0rem;
        padding-left: 5px;
        color: #2A2A2A;
        font-size: 18px;
        text-align: left;
        padding-bottom: 15px;
    }

    .del__edi__holder {
        display: flex;
        align-items: center;

        .edi {
            margin-right: 20px;
            cursor: pointer;
            color: #2A2A2A7F;
        }

        .dele {
            cursor: pointer;
            color: #E36176;
        }
    }

}


.edit__cate__holder {
    width: 100%;
}




.view__modal__user {
    width: 100%;
}

.view__modal__user1 {
    padding: 0rem 3rem;
}


.add__new {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
    margin-right: 30px;

    .nwe {
        background-color: #D3A937;
        padding: .6rem 1rem;
        color: #fff;
        border-radius: 4px;
    }
}

.tabs__holder {
    display: flex;
    align-items: center;
    margin-left: 20px;

    .tab__btn {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: .6rem 1rem;
        margin-bottom: 30px;
    }
}




.btns2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 4rem;
}

.c__btn {
    background: none;
    padding: .5rem 2rem;
    border-radius: 4px;
    border: 1px solid #D3A937;
}

.c__btn__1 {
    padding: .5rem 2rem;
    border-radius: 4px;
    color: white;
    background-color: #D3A937;
}




.view__modal__us {
    width: 100%;
}

.flex__modal {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 1024px) {

    .main-panel--content {
        padding-left: 10.75rem;
        padding-right: 0.25rem;
    }

    .resulttable__wrapper {
        padding-left: 4rem;
        padding-right: 0rem;
    }

}

@media (max-width: 820px) {

    .resulttable__wrapper {
        padding-left: 0rem;
        padding-right: 0rem;
    }

    .resulttable__wrapper {
        padding: 0rem;
        // padding-left: 1rem;
        border: none;

        tr {
            box-shadow: 0px 1px 1px #2a2a2a3f;
        }

        td {
            padding-right: 0rem;
            padding-top: .5rem;
        }
    }

    th {
        padding-right: 4rem;
        padding-left: 5px;
        color: #2A2A2A;
        font-size: 18px;
        text-align: left;
        padding-bottom: 15px;
    }

}
@media (max-width: 912px) {

    .resulttable__wrapper {
        padding-left: 0rem;
        padding-right: 0rem;
    }

    .resulttable__wrapper {
        padding: 0rem;
        padding-left: 4rem;
        border: none;

        tr {
            box-shadow: 0px 1px 1px #2a2a2a3f;
        }

        td {
            padding-right: 0rem;
            font-size: 10px;
            padding-top: .5rem;
        }

        .result__header__items>p {
            font-size: 11px;
        }
    }

    th {
        padding-right: 4rem;
        padding-left: 5px;
        color: #2A2A2A;
        font-size: 18px;
        text-align: left;
        padding-bottom: 15px;
    }

}


@media (max-width: 540px) {

    .resulttable__wrapper {
        padding-left: 0rem;
        padding-right: 0rem;
    }

    .result__data__items__holder {
        padding: 1rem !important;
    }

    .resulttable__wrapper {
        padding: 0rem;
        padding: 0rem 1rem;
        border: none;

        tr {
            box-shadow: 0px 1px 1px #2a2a2a3f;
        }

        td {
            padding-right: 0rem;
            font-size: 10px;
            padding-top: .5rem;
        }

        .result__header__items>p {
            font-size: 11px;
        }
    }

    th {
        padding-right: 4rem;
        padding-left: 5px;
        color: #2A2A2A;
        font-size: 18px;
        text-align: left;
        padding-bottom: 15px;
    }

}


@media (max-width: 280px) {

    .resulttable__wrapper {
        padding-left: 0rem;
        padding-right: 5rem;
        width: 118% !important;
    }

    .setup-container .tabs-container {
        padding-top: 0rem;
        padding-right: .5rem;
        font-size: 16px !important;
        margin-bottom: 1rem;
    }

    .result__table__container3 {
        padding: 1rem 0;
        width: 100% !important;
        border: none;
        margin-top: 30px;

        .result__data__items {
            padding-left: 0px;
        }

    }

}

