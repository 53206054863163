.search-input--form {
  display: flex;
  width: 100%;
  // max-width: 350px;
  min-width: 250px;
  // overflow: hidden;
  border-radius: 6px;
}

.form--control {
  display: flex;
  flex-direction: column;
  //gets all direct children abd set their height to the same height as their parent cntainer
  & > * {
    height: 100%;
  }
}

.button__submit {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: var(--clr-primary-300);
}

.input-field--holder {
  width: 100%;

  & input[type="search"] {
    border: 1px solid transparent;
    padding: 0.5rem;
    font-size: 1.15rem;
    background-color: var(--clr-dark-100);
    outline: 0;
  }
}

