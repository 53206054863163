
.textInput-container {
    width: 100%;
   
    .text-input {
        width: 100%;
        background-color: #2A2A2A0C;
        padding: .9rem;
        border: none;
        margin: .5rem 0;
        font-size: .9rem;
        border-radius: .5rem;
        outline: none;
        
        
    }
}
// *************** @Media screens ***************
@media (max-width: 1740px) {
        .label {
            font-size: 14px;
        }
  
    }