.generic--button {
  display: flex;

  .primary--button {
    background-color: var(--clr-primary-300);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: var(--lato-regular-font);
    text-transform: uppercase;
    padding: 0.1rem 1.15rem;
    border-radius: 4px;
    color: #fff;
    @media screen and (max-width: 414px) {
     padding: 0.1rem 0.7rem;
  }
    & span {
      font-size: calc(1rem - 3px);
      font-weight: 550;
      letter-spacing: 0.25px;
      padding: 0.5rem;
      text-align: center;
      cursor: pointer;
      
      &:last-child {
        padding-top: 0.7rem;
        @media (max-width: 850px) {
         font-size: 0.75rem;
        }
    }

    }

    & svg {
      font-size: 1.25rem;
    }

    &.btn--small {
      width: 150px;
    }

    &.btn--large {
      min-width: 300px;
    }
  }
  .voters-print {
    color: #341A1C;
  }

  .add-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.button {
  background-color: var(--clr-primary-300);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: var(--lato-regular-font);
  // text-transform: uppercase;
  padding: 0.1rem 1.15rem;

  border-radius: 4px;
  color: #fff;
  & span {
    font-size: calc(1rem - 3px);
    font-weight: 550;
    letter-spacing: 0.25px;
    padding: 0.5rem;
    text-align: center;
    cursor: pointer;
    
    &:last-child {
      padding-top: 0.7rem;

  }

  }

  & svg {
    font-size: 1.25rem;
  }

  &.btn--small {
    width: 150px;
  }

  &.btn--large {
    min-width: 300px;
  }
 
}