.card-holder {
    padding: .8rem;
    border-radius: .5rem;
    background-color: #2A2A2A0C;
    cursor: pointer;
    flex: 1;
    

    .header-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .phase-title {
            display: flex;
            gap: .4rem;
            align-items: center;
            margin-bottom: 1.5rem;

            span:first-child {
                color: #E36176;
                font-size: 14px;
            }
            span:last-child {
                font-weight: bold;
                text-transform: uppercase;
                color: #2A2A2A;
                font-size: 14px;
            }
        }
        p:last-child {
            color: #2A2A2A7F;
            font-size: 12px;
        }
    }
    
    .description-section {
        p{
            display: flex;
            flex-direction: column;
            font-size: 14px;
            &span {
                color: #2A2A2A;
                text-align: justify;

            }
        }
       
        
    }
    .card-footer{
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;

        p,ul,li.active {
            color: #2A2A2A7F;
            font-size: 14px;
            list-style: disc;
        }

        p:last-child>ul {
            display: flex;
            gap: .8rem;

            span,li {
                padding-right: 1rem;
            }
        }
    }
}

// *************** @Media screens ***************
@media (max-width: 1740px) {

  }