
.view__modal__use {
    padding: 0.5rem;
}
.edit__cate__tit {
margin-bottom: 1rem;
}
.edit__cate__title12 {
    border-bottom: 2px solid #2A2A2A0C;
    margin-bottom: 20px;
}

.update__bbt111 {
    background-color: #D3A937;
    padding: .5rem 5rem;
    margin-bottom: 30px;
    border-radius: 5px;
}

@media (max-width: 1024px) {
    .main-panel--content {
        padding-left: 15.75rem;
        padding-right: 1.25rem;
    }
}

@media (max-width: 280px) {
    .update__bbt111 {
        padding: 0.5rem 2rem;
    }

    .dal__user {
        padding: 0rem 0rem;
    }
}

