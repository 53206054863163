.round-user-avatar {
  display: flex;
  flex-direction: column;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 100%;

  & img {
    width: inherit;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 540px) {

    .round-user-avatar {
      width: 35px;
      height: 35px;
  
      & img {
        width: inherit;
        height: 100%;
        object-fit: cover;
      }
      
    }
  }