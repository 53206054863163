.add-btn-wrapper {
height: 40px;
}
.team-user {
    display: flex;
    gap: 1rem;
    // text-wrap: nowrap;
    align-items: center;
    & > img {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        object-fit: cover;
    }
}
.teams-wrapper {
    overflow: auto;
}


.result__table__container2 {
    padding: 1rem;
    width: 100%;

    tr {
        box-shadow: 0px 1px 1px #2a2a2a3f;
    }

    td {
        // padding-right: 12rem;
        padding: 1rem;
        font-size: 16px;
        color: #2A2A2A7F;
    }


    .result__data__items {
        padding-left: 5px;
    }

    .result__data__itemss {
        padding-left: 5px;
    }

    th {
        padding-right: 4rem;
        padding-left: 5px;
        color: #2A2A2A;
        font-size: 18px;
        text-align: left;
        padding-bottom: 15px;
    }

    .del__edi__holder {
        display: flex;
        align-items: center;

        .edi {
            margin-right: 20px;
            cursor: pointer;
            color: #2A2A2A7F;
        }

        .dele {
            cursor: pointer;
            color: #E36176;
        }
    }

}

.add__new {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
    margin-right: 30px;

    .nwe {
        background-color: #D3A937;
        padding: .6rem 1rem;
        color: #fff;
        border-radius: 4px;
    }
  
}

.tabs__holder {
    display: flex;
    align-items: center;
    margin-left: 20px;

    .tab__btn {
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        padding: .6rem 1rem;
        margin-bottom: 30px;
    }
}

.btns2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.c__btn {
    background: none;
    padding: .5rem 2rem;
    border-radius: 4px;
    border: 1px solid #D3A937;
}

.c__btn__1 {
    padding: .5rem 2rem;
    border-radius: 4px;
    color: white;
    background-color: #D3A937;
}

.sure__del {
    text-align: center;
    font-size: 24px;
    margin-bottom: 5rem;
}


.cat__name {
    margin-bottom: 20px;
}

.update__bbtn {
    background-color: #D3A937;
    padding: .5rem 5rem;
    border-radius: 4px;
    color: white;
    margin-top: 30px;
}

.view__modal__us {
    width: 100%;
}

.flex__modal {
    display: flex;
    justify-content: space-between;
}

.white__bg--hold {
    background-color: white;
    padding: 1rem;
}



@media (max-width: 912px) {

    .result__table__container2 {
        padding: 0rem;
        width: 100%;


        td {
            padding-right: 1rem;
            padding-top: .5rem;
            padding-bottom: .5rem;
            font-size: 16px;
            color: #2A2A2A7F;
        }

    }

}

@media (max-width: 820px) {

    .white__bg--hold {
        background-color: unset !important;
        padding: 0rem !important;
    }

}

@media (max-width: 540px) {

    .result__table__container2 {
        padding: 0rem;
        width: 100%;


        td {
            padding-right: 1rem;
            padding-top: .5rem;
            padding-bottom: .5rem;
            font-size: 16px;
            color: #2A2A2A7F;
        }

    }

}