.edit__cate__holder {
    padding: 1rem;
}
.page-container {
    max-width: 30rem;

    .c-header {
        margin-bottom: 2rem;
        border-bottom: 1px solid #2A2A2A0C;
        padding-bottom: .4rem;
    }

    .prize-input {
        margin-top: 5.5rem;
        width: 100%;

    }
    .description-text {
        margin: 1rem 0;
    }
    .save-btn {
    margin-top: 2rem;

    .saved-button {
            background-color: var(--clr-primary-300);
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: var(--lato-regular-font);
            text-transform: uppercase;
            padding: 0.2rem 1.15rem;
            border-radius: 4px;
            color: #fff;
            width: 200px;

            & span {
                font-size: calc(1rem - 3px);
                font-weight: 550;
                letter-spacing: 0.25px;
                padding: 0.5rem;
                text-align: center;

            }

            & svg {
                font-size: 1.25rem;
            }

            &.btn--small {
                width: 150px;
            }

            &.btn--large {
                min-width: 300px;
            }
        }
    }
  
}


@media screen and (max-width: 912px) {

    .main-panel--content {
        padding-left: 15.75rem;
        padding-right: 0.25rem;
    }

}

@media screen and (max-width: 820px) {

    .main-panel--content {
        padding-left: 1rem;
        padding-right: 0.25rem;
    }

    .drpdwn-container .filter-btn {
        width: 100% !important;
    }

}