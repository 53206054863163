.setupTerms-container {
    display: flex;
    margin-top: 2rem;
    margin-left: 5rem;
    
            h3 {
        font-size: 16px;
        color: #2A2A2A;
        }

        p {
            font-size: 16px;
            color: #2A2A2ABF;
        }
        .terms {
            width: 60%;
            text-align: justify;
        }

        }
    .or {
        width: fit-content;
        margin: 1rem auto;
    }

    .description-container11 {
        // padding-left: 5rem;
        padding-top: 2rem;
    }

 .upload-img {
    border: 1px dashed #707070;
    display: flex;
    width: 30rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 150px;

    span {
        color: #D3A937;
    }

    p {
        font-size: 10px;
    }

}
.upload-file-now {
    width: 500px;
    margin-top: 1rem;
}

.view__terms {
    margin-top: 2rem;
    text-decoration: underline;
    margin-bottom: 2rem;
}
.upload-file {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 1rem;
    & > button {
        margin-top: 1rem;
    }
}


@media screen and (max-width: 414px) {

    .setupTerms-container {
        margin-left: 2rem !important;
    }

}



