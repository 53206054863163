.imgfile-container {
  width: 100%;
  border: 1px dashed #D3A937;
  height: 100%;
  max-height: 300px;
  text-align: center;
  @media (max-width: 414px) {
    height: 200px;
  }
  img {
    width: 100%;
    max-height: 100%;
    object-fit: cover;
    max-height: 300px;
    @media (max-width: 414px) {
     max-height: 200px;
    }
  }
  input {
    opacity: 0;
  }
}

 .form-file-holder{
    width: 100%;
    height: 100%;
    cursor: pointer;
    color: #D3A937;
    font-size: 14px;
    border-radius: 5px;
    padding: 5px 8px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .icon-tree {
      font-size: 34px;
    }
    span:nth-child(3) {
      font-size: 12px;
    }
  button {
    width: 100%;
    background-color: transparent;
  }

  input{
    opacity: 0;
  
  }
}

.change-img-holder {
  margin: 1rem 0;
  button {
    background-color: #D3A937;
    color: #fff;
    width: 100%;
    padding: .3rem 0;
    border-radius: .3rem;
  }
}

.error-msg {
  color: red;
  font-style: italic;
  font-size: 0.75rem;
}
