.dashboard-header {
  background-color: #fff;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  &__content {
    width: 100%;

  }

  &__heading {
    text-align: center;
    font-family: var(--lato-bold-font);
    color: var(--text-primary-color);
  }
}

.dashboard-header__content,
.dashboard-header__right-content {
  display: flex;
  align-items: center;
  // background-color: red;
  
}

.dashboard-header__content {
  justify-content: space-between;
  padding: 0 5rem;

  h2 {
    padding-left: 14rem;
  }
}

.button__holder {
  display: flex;
  flex-direction: column;
  max-width: 150px;
  min-width: 100px;
  position: relative;
  border-radius: 5px;
  position: relative;
}

// *************** @Media screens ***************
@media (max-width: 1740px) {
  .dashboard-header__content {
    justify-content: space-between;
    // margin-right: 8rem;
    padding: 0 2rem;
  }  

}

@media (max-width: 1800px) {
  .dashboard-header__content {
    justify-content: space-between;
    // margin-right: 0rem;
    padding: 0 2rem;
  }
}



@media (max-width: 1024px) {
  .dashboard-header__content {
    margin-right: 0rem;
  }
}

@media (max-width: 875px) {
  .dashboard-header__heading {
      display: none;
  }

  .dashboard-header__content {
      h2 {
        padding-left: 0rem;
      }
    }
}



@media (max-width: 414px) {
  
  .dashboard-header__content {
    h2 {
      padding-left: 0rem;
    }
  }

    .dashboard-header__search {
      display: flex;
      justify-content: center;
      align-items: center;
    }
}