.avatar-button {
  display: flex;
  flex-direction: column;
  max-width: 150px;
  min-width: 120px;
}

.avatar-button__toggle {
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 0.5rem;
  padding-right: 0px;
  background-color: #fff;
}

.avatar-button__icon {
  display: flex;
  flex-direction: column;
  width: 25px;
  transition: transform 0.5s ease-in;

  //Javascript Class
  &.icon--rotated {
    transform: rotate(180deg);
  }
  &.icon--unset-rotate {
    transform: rotate(0);
  }
  & svg {
    height: 100%;
    width: 100%;
  }
}


@media (max-width: 1280px) {

  .avatar-button__toggle {
    margin-left: 0rem;
  }

  .avatar-button {
    display: flex;
    flex-direction: column;
    // margin-right: 18rem;
    max-width: 150px;
    min-width: 120px;
  }
}

@media (max-width: 875px) {

  .avatar-button__toggle {
    margin-left: -10rem;
  }

    .avatar-button {
      display: flex;
      flex-direction: column;
      // margin-right: 18rem;
      max-width: 150px;
      min-width: 120px;
    }
}

@media (max-width: 820px) {

  .avatar-button__toggle {
    margin-left: 0rem;
  }

  .avatar-button {
    display: flex;
    flex-direction: column;
    // margin-right: 18rem;
    max-width: 150px;
    min-width: 120px;
  }
}

@media (max-width: 540px) {

  .avatar-button__toggle {
    margin-left: 0rem;
  }

  .avatar-button {
    display: flex;
    flex-direction: column;
    // margin-right: 18rem;
    max-width: 100px;
    min-width: 90px;
  }
}

@media (max-width: 414px) {

  .avatar-button {
    display: flex;
    flex-direction: column;
    // margin-right: 18rem;
    max-width: 100px;
      min-width: 90px;
  }
}


@media (max-width: 412px) {

  .avatar-button__toggle {
    margin-left: -6rem;
  }

  .avatar-button {
    display: flex;
    flex-direction: column;
    // margin-right: 18rem;
    max-width: 100px;
    min-width: 90px;
  }
}


@media (max-width: 360px) {

  .avatar-button__toggle {
    margin-left: -6rem;
  }

  .avatar-button {
    display: flex;
    flex-direction: column;
    // margin-right: 18rem;
    max-width: 100px;
    min-width: 90px;
  }
}

@media (max-width: 280px) {

  .avatar-button__toggle {
    margin-left: -16rem;
  }

  .avatar-button {
    display: flex;
    flex-direction: column;
    // margin-right: 18rem;
    max-width: 100px;
    min-width: 90px;
  }
}
